
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <v-layout row wrap px-4 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.stock_move_types.list_path">
                            <template v-slot:item="{item}">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                    {{ item.title }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3></v-flex>
                </v-layout>
                <form @submit.prevent="addStockMoveTypes" autocomplete="off">
                    <v-layout row wrap>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="stock_move_types.stock_move_type_name" type="text" :label="$store.getters.language.data.stock_move_types.stock_move_type_name" dense
                                class="mx-1"  filled outlined required>
                            </v-text-field>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable  :items="[{text: 'Add', value: 'add'}, {text: 'Remove', value: 'remove'}]" v-model="stock_move_types.stock_move_type_type" dense  filled outlined item-text="text"
                                item-value="value" :return-object="false" :label="$store.getters.language.data.stock_move_types.stock_move_type_type">
                            </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit"  >{{$store.getters.language.data.stock_move_types.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers"   v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="stock_move_type_id">
                    <template v-slot:[`item.stock_move_type_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/stock_move_types-list/'+item.stock_move_type_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectStockMoveTypes(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteStockMoveTypesList">{{$store.getters.language.data.stock_move_types.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.stock_move_types.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.stock_move_types.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteStockMoveTypes(selected_stock_move_types.stock_move_type_id)">
                {{$store.getters.language.data.stock_move_types.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/stock_move_types.request.js'
    export default {
        data() {
            return {
                stock_move_types: {},
                search: '',
                loading : false,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                
                selected_stock_move_types : {},
                delete_dialog: false,
                headers: [
                    { 
                        text: this.$store.getters.language.data.stock_move_types.stock_move_type_name,
                        align: 'start',
                        sortable: true,
                        value: 'stock_move_type_name',
                    },
                    { 
                        text: this.$store.getters.language.data.stock_move_types.stock_move_type_type,
                        align: 'start',
                        sortable: true,
                        value: 'stock_move_type_type',
                    },   
                    {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'stock_move_type_id',
                    }
                ],
            }
        },
        computed: {
            rows() {
                return this.$store.getters.stock_move_types_list
            }
        },
        mounted(){
            
        },
        methods: {
            addStockMoveTypes() {
                this.loading_btn = true
                this.stock_move_types.store_id = this.$store.getters.store.store_id;
                requests.createStockMoveTypes(this.stock_move_types).then(r => {
                    if (r.status == 200) {
                        this.stock_move_types = {}
                        this.$store.state.stock_move_types_list.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.add_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteStockMoveTypes(stock_move_type_id) {
                requests.deleteStockMoveTypes(stock_move_type_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.$store.state.stock_move_types_list = this.$store.state.stock_move_types_list.filter(f =>{
                            return f.stock_move_type_id != stock_move_type_id
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            
            deleteStockMoveTypesList() {
                let ids = this.selected_rows.map(m => m.stock_move_type_id)
                requests.deleteStockMoveTypesList(ids).then(r => {
                    if (r.status == 200) {
                        this.$store.state.stock_move_types_list = this.$store.state.stock_move_types_list.filter(f =>{  
                            return ids.indexOf(f.stock_move_type_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            selectStockMoveTypes(i) {
                this.selected_stock_move_types = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    